@import "../../_globalColor";

.skills-main-div {
  display: flex;
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
}

.skills-text-div {
  margin-right: 50px;
  flex: 1;
}

.skills-main-div > * {
  margin-bottom: 30px;
}

.skills-image-div {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.skills-image-div > img {
  max-width: 100%;
  height: auto;
}

.skills-heading {
  font-size: 56px;
  font-weight: 400;
}

.subTitle {
  color: $subTitle;
}

/* Media Query */
@media (max-width: 1380px) {
  .skills-heading {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .skills-heading {
    font-size: 30px;
    text-align: center;
  }

  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
  }

  .skills-main-div {
    flex-direction: column;
  }

  .skills-text-div {
    margin: 20px;
    margin-bottom: 0; /* Ensure it doesn't overlap with image */
  }

  .skills-text {
    font-size: 16px;
  }

  .skills-text-subtitle {
    font-size: 16px;
    text-align: center;
  }

  .skills-image-div {
    order: 2;
    justify-content: center;
  }
}
