@import "../_globalColor"; // Importing global variables like colors

.project-page-container {
    padding: 40px;
    max-width: 1200px; 
    margin: 0 auto;
    
    .project-title {
      font-size: 56px;
      font-weight: 400;
      color: $titleColor;
      text-align: left;
      margin-bottom: 40px;
      margin-left: 40px;
    }

    .project-blurb {
      color: $subTitle;
      font-size: 18px;
      line-height: 1.6rem;
      text-align: left;
      margin-left: 40px;
      margin-right: 40px;
      margin-bottom: 20px;

      .link{
        a {
        color: $buttonColor;
        text-decoration: none;
        font-size: 20px;
        font-weight: 800;
        transition: 0.2s ease-in;
      }
      &:hover {
        color: $buttonHover;
        text-decoration: underline;
      }
      }
    }
  
    .project-image {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      width: 100%; /* Ensure the container uses full available width */
      height: auto; /* Let height adjust automatically */
      
      .image {
        max-width: 100%; /* Make sure image does not exceed its container's width */
        max-height: 600px; /* Limit the height */
        width: auto; /* Maintain the aspect ratio */
        height: auto; /* Scale the image proportionally */
        box-shadow: $lightBoxShadowDark 0px 10px 30px -15px;
        transition: all 0.3s ease;
        object-fit: contain; /* Ensure the entire image fits within its container */
        
        &:hover {
          box-shadow: $lightBoxShadowDark 0px 20px 30px -10px;
          transform: translateY(-5px);
        }
      }
    }
  
    /* Second Blurb with Two Columns */
    .project-blurb-columns {
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* Two equal columns */
      gap: 20px; /* Space between the columns */
      margin-left: 40px;
      margin-right: 40px;
      margin-bottom: 20px;
      line-height: 1.6rem;
      
      h3 {
        font-size: 24px;
        color: $titleColor;
        margin-bottom: 10px;
      }
    
      h4 {
        font-size: 18px;
        color: $subTitle;
        margin-top: 20px;
        margin-bottom: 10px;
      }
    
      ul {
        list-style-type: disc;
        color: $subTitle;
        margin-left: 20px; /* Indentation for list items */
      }
    
      li {
        margin-bottom: 10px;
        color: $subTitle;
      }
    }

    .project-pdfs {
      margin-top: 20px;
      margin-left: 40px;
      margin-right: 40px;
  
      h3 {
        font-size: 24px;
        color: $titleColor;
        margin-bottom: 1rem;
      }
  
      ul {
        list-style-type: none;
        padding-left: 0;
      }
  
      li {
        margin-bottom: 10px;
  
        a {
          color: $buttonColor;
          text-decoration: none;
          font-size: 18px;
          transition: 0.2s ease-in;
  
          &:hover {
            color: $buttonHover;
            text-decoration: underline;
          }
        }
      }
    }
  }
  
  // Media queries for mobile
  @media (max-width: 768px) {
    .project-page-container {
      padding: 20px;
    }
  
    .project-title {
      font-size: 36px;
      margin-left: 20px;
    }

    .project-blurb, .project-blurb.columns {
      grid-template-columns: 1fr; /* Stack columns in one for small screens */
      margin-left: 20px;
      margin-right: 20px;
    }
  
    .project-pdfs {
      margin-left: 20px;
      margin-right: 20px;
    }
  }