@import "../../_globalColor";

.main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 4rem;
}
.greet-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem;
}

.greeting-main {
  display: flex;
}

.subTitle {
  color: $subTitle !important;
}

.greeting-main {
  display: flex;
}

.greeting-main > * {
  flex: 1;
  margin-bottom: 30px;
}
.button-greeting-div {
  display: flex;
  margin-top: 20px;
}
.download-link-button {
  text-decoration: none;
}

.greeting-text {
  font-size: 70px;
  line-height: 1.1;
  color: $textColor !important;
}

.greeting-text-p {
  font-size: 30px;
  line-height: 40px;
}

.greeting-image-div > img {
  border-radius: 70%;
  width: 75%;
  margin-left: 110px;
  transition: transform .2s
}

.greeting-image-div:hover > img {
  transform: scale(1.1);
}


.wave span {
  display: inline-block;
  font-size: 80px;
  animation: wave 2.5s forwards;
  animation-delay: calc(0.1s * var(--i));
}

.wave:hover span{
  display: inline-block;
  font-size: 80px;
  animation: wave-hover 2.5s forwards;
  animation-delay: calc(0.1s * var(--i));
}


/* Keyframes for hover animation */
@keyframes wave-hover {
  0%, 100% {
    transform: translateY(0) scale(1);
    color: black; /* Color back to black */
  }
  50% {
    transform: translateY(20px) scale(1); /* Increase size */ /* Change color to pink on hover */
  }
}

@keyframes wave {
  0%, 100% {
    transform: translateY(0) scale(1);
    color: black; /* Color back to black */
  }
  50% {
    transform: translateY(20px) scale(1); /* Increase size */ /* Change color to pink on hover */
  }
}


.wave-emoji {
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-name: wave;
  display: inline-block;
  transform-origin: 70% 70%;
}



/* Media Query */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 50px;
  }
  .greeting-text-p {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .button-greeting-div {
    justify-content: space-around;
  }
  .greeting-text {
    font-size: 30px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
  .greeting-main {
    display: block;
  }
  .greeting-image-div {
    height: 256px;
  }
}

@media (max-width: 320px) {
  .main {
    width: auto;
  }
}
