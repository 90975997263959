@import "../_globalColor"; // Import global color variables

.contact {
  padding: 40px;

  h1 {
    font-size: 56px;
    font-weight: 400;
    margin-bottom: 60px;
    color: $titleColor;
    margin-left: 0px;
  }

  p {
    color: $subTitle;
    margin-left: 5px;
  }

  .contact-container {
    display: grid; /* Use CSS Grid layout */
    grid-template-columns: 1fr; /* Single column layout */
    gap: 1.5rem; /* Spacing between form sections */
    margin-left: 40px;
    margin-right: 40px;
  }

  .contact-form {
    background-color: $lightBackground2;
    border: 1px solid $lightBorder1;
    border-radius: 10px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    box-shadow: $lightBoxShadowDark 0px 10px 30px -15px;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: $lightBoxShadowDark 0px 20px 30px -10px;
      transform: translateY(-5px);
    }

    .form-group {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    label {
      font-size: 18px;
      color: $titleColor;
    }

    input, textarea {
      border: 1px solid $lightBorder1;
      border-radius: 5px;
      padding: 1rem;
      font-size: 16px;
      color: $textColor;
      background-color: $lightBackground1;
      resize: vertical; /* Allow vertical resizing for textarea */
      width: 98%; /* Ensure inputs and textarea take full width */
      margin-right:30px;
      margin-bottom: 30px;
    }

    textarea {
      min-height: 150px; /* Set a minimum height for the textarea */
    }

    button {
      background-color: $buttonColor;
      color: $lightBackground3;
      border: none;
      border-radius: 5px;
      padding: 0.75rem 1.5rem;
      font-size: 16px;
      cursor: pointer;
      transition: 0.2s ease-in;

      &:hover {
        background-color: $buttonHover;
        transform: scale(1.05);
      }
    }

    .validation-error {
      font-size: 14px;
    }
  }

  @media (max-width: 768px) {
    .contact-container {
      margin-left: 20px;
      margin-right: 20px;
    }

    .contact-form {
      padding: 1.5rem;
    }
  }
}

