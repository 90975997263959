@import "../../_globalColor";

.main {
  padding: 1rem;
}

.about-header-title {
  font-size: 56px;
  font-weight: 400;
  margin-bottom: 2rem;
  text-align: left;
}

.about-header-paragraph {
  margin-bottom: 2rem;
  color: #333;
  max-width: 75%;
  text-align: left;
  font-size: 20px;
  line-height: 24px;
}

.about-images {
  display: flex;
  justify-content: center;
  gap: 3rem;
}

.about-image:hover {
  transform: scale(1.2);
}

.about-image {
  width: 400px;
  height: 500px;
  object-fit: cover;
  transition: transform .2s;
}

/* Tablet Adjustments */
@media (max-width: 1024px) {
  .about-header-title {
    font-size: 48px;
    text-align: center;
  }

  .about-header-paragraph {
    font-size: 18px;
    line-height: 22px;
    max-width: 90%;
    text-align: center;
  }

  .about-images {
    gap: 2rem;
    flex-wrap: wrap;
  }

  .about-image {
    width: 300px;
    height: 400px;
  }
}

/* Mobile Adjustments */
@media (max-width: 600px) {
  .main {
    padding: 0.5rem;
  }

  .about-header-title {
    font-size: 36px;
    text-align: center;
  }

  .about-header-paragraph {
    font-size: 16px;
    line-height: 20px;
    max-width: 100%;
    text-align: center;
  }

  .about-images {
    flex-direction: column;
    gap: 1rem;
  }

  .about-image {
    width: 100%;
    height: auto;
  }
}
