@import "../_globalColor";

.password-protected-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh; /* Full viewport height */
    text-align: center; /* Center text */
    background-color: white; /* Light background color */
  }
  
  .password-protected-container h2 {
    margin-bottom: 20px; /* Space between title and form */
  }
  
  .password-protected-container form {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between form elements */
    max-width: 400px; /* Optional: Limit form width */
    width: 100%;
  }
  
  .password-protected-container input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .password-protected-container button {
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    background-color: $buttonColor; /* Bootstrap primary color */
    color: white;
    cursor: pointer;
  }
  
  .password-protected-container button:hover {
    background-color: $buttonHover; /* Darker blue on hover */
  }
  
  .password-protected-container p {
    color: $buttonColor;
    margin-top: 10px;
  }
  