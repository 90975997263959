@import "../_globalColor";

.projects {
  padding: 40px;

  h1 {
    font-size: 56px;
    font-weight: 400;
    margin-bottom: 60px;
    color: $titleColor;
    margin-left: 0px;
  }

  p {
    color: $subTitle;
    margin-left: 5px;
  }

  .projects-container {
    display: grid; /* Use CSS Grid layout */
    grid-template-columns: repeat(2, 1fr); /* Set two cards per row */
    gap: 1.5rem; /* Spacing between cards */
    margin-left: 40px;
    margin-right: 40px;
  }

  .project-card {
    background-color: $lightBackground2;
    border: 1px solid $lightBorder1;
    border-radius: 10px;
    padding: 2rem;
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    box-shadow: $lightBoxShadowDark 0px 10px 30px -15px;
    transition: all 0.3s ease;
    height: 500px;

    &:hover {
      box-shadow: $lightBoxShadowDark 0px 20px 30px -10px;
      transform: translateY(-5px);
    }

    .project-image {
      height: auto;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;

      .card-image {
        max-width: 100%;
        height: auto;
        width: 300px;
      }
    }

    .project-detail {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;

      .card-title {
        color: $titleColor;
        font-size: 26px;
        line-height: 30px;
        font-weight: 700;
        margin-bottom: 0.5rem;
        position: relative;
        top: -40px;
        right: -5px;
      }

      .card-subtitle {
        color: $cardSubtitle;
        font-size: 22px;
        line-height: 1.4rem;
        max-width: 350px;
        margin-bottom: 1rem;
        position: relative;
        top: -40px;
      }

      .project-card-footer {
        margin-top: 1rem;
        display: flex;
        gap: 10px;

      .project-icon { // Adjust height as needed
          margin: 0 8px;
          // Ensure icons are displayed correctly
          display: inline-block; 
        }

        .project-tag {
          background: $buttonColor;
          color: $lightBackground3;
          border-radius: 10px;
          display: inline-flex;
          font-size: 16px;
          height: 2.5em;
          align-items: center;
          justify-content: center;
          padding: 0 1.2em;
          cursor: pointer;
          transition: 0.2s ease-in;
          text-decoration: none;
          line-height: 1.5;
          white-space: nowrap;

          &:hover {
            background: $buttonHover;
            transform: scale(1.05);
          }

          &.github-link {
            background: $iconBackground;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .card-subtitle {
      font-size: 16px;
      text-align: center;
      line-height: 1.2rem;
      max-width: 100%;
    }

    .projects-container {
      grid-template-columns: 1fr; /* Stack cards in a single column on small screens */
    }

    .project-card {
      flex-direction: column;
      text-align: center;
      padding: 1.5rem;

      .project-image {
        margin-bottom: 1.5rem;
      }
    }
  }
}
